import algoliasearch from "algoliasearch";
import Filters from "./api/Filters";
import Store from "@/store";
import config from "@/config";
import Hit from "@/models/algolia/Hit";

const AlgoliaClient = {};

AlgoliaClient.client = null;
AlgoliaClient.index = null;
AlgoliaClient.indexList = null;

AlgoliaClient.Init = async () => {
    if (AlgoliaClient.client === null) {
        AlgoliaClient.client = algoliasearch(
            config.algolia.appid,
            config.algolia.apikey
        );
    }

    if (AlgoliaClient.index === null) {
        AlgoliaClient.index = AlgoliaClient.client.initIndex(
            config.algolia.defaultIndex
        );
    }

    if (AlgoliaClient.indexList === null) {
        AlgoliaClient.indexList = config.algolia.indexList;
    }
};

AlgoliaClient.search = async function(filters, page = 0, hitsPerPage = 20) {
    const query = Store.getters["algolia/getQuery"];
    const options = AlgoliaClient.createSearchOptions(filters, page);
    options.hitsPerPage = hitsPerPage;

    const response = await AlgoliaClient.index.search(query, options);
    response.hits = response.hits.map((hit) => {
        // Samsung browser somehow caches the repsone paramater.
        // This causes the hit object to have nested _data propety
        if (hit["_data"]) {
            return new Hit(hit._data);
        }

        return new Hit(hit);
    });

    return response;
};

AlgoliaClient.createSearchOptions = function(_filters, page) {
    const options = {};
    options.facetFilters = [];

    let filters = !_filters.length ? Object.assign(_filters) : {};

    for (const filterName in filters) {
        if (Object.hasOwnProperty.call(filters, filterName)) {
            if (filters[filterName].type === "range") {
                //If the filter already exist we need to add AND to string.
                if (options.filters) {
                    options.filters = ` AND ${filterName}:${filters[filterName].values.min} TO ${filters[filterName].values.max}`;
                }

                options.filters = `${filterName}:${filters[filterName].values.min} TO ${filters[filterName].values.max}`;
                continue;
            }

            let filterResult = [];

            for (const value of filters[filterName].values) {
                filterResult.push(`${filterName}:${value}`);
            }

            options.facetFilters.push(filterResult);
        }
    }

    options.page = page;

    return options;
};

/**
 * @param {string} index
 */
AlgoliaClient.setSortIndex = function(index) {
    AlgoliaClient.index = AlgoliaClient.client.initIndex(index);
};

/**
 * Returns all the filters from the backend api.
 *
 * @return {Filter[]}
 */
AlgoliaClient.getFiltersFromApi = async function() {
    // TODO should be cached
    return await Filters.GetList();
};

/**
 * Set filter
 *
 * @param {Filter} filter
 * @param {string|Object}  value Value
 * @param {int}  value.min Min value for range
 * @param {int}  value.max Max value for range
 * @return {void}
 */
AlgoliaClient.addFilter = function(filter, value) {
    Store.dispatch("algolia/addFilter", {
        filterSlug: filter.getSlug(),
        value: value,
        filterType: filter.getType(),
    });
};

/**
 * @param {Filter} filter
 * @return {void}
 */
AlgoliaClient.removeFilter = function(filter) {
    Store.commit("algolia/removeFilter", {
        filterSlug: filter.getSlug(),
    });
};

/**
 * @param {Filter} filter
 * @param {string|Object}  value Value
 * @param {int}  value.min Min value for range
 * @param {int}  value.max Max value for range
 * @return {void}
 */
AlgoliaClient.removeFilterValue = function(filter, value) {
    if (filter.getType() === "slider") {
        // Range filters just need a reset
        Store.commit("algolia/removeFilter", {
            filterSlug: filter.getSlug(),
        });
    }

    Store.commit("algolia/removeListValue", {
        filterSlug: filter.getSlug(),
        value: value,
    });
};

AlgoliaClient.getActiveFilterValues = function(filters, filterSlug) {
    const activeFilter = filters[filterSlug];

    if (!activeFilter || !activeFilter.values) {
        return [];
    }

    return activeFilter.values;
};

export default AlgoliaClient;
