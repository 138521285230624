import HTTPClient from "@/lib/HTTPClient";
import Recipe from "@/models/Recipe";

const Recipes = {};

/**
 * Gets recipe by slug
 *
 * - Does API request through HTTPClient
 * - Return recipe as recipe dataobject
 *
 * @param   {string}  slug  The slug of the recipe
 *
 * @return  {Recipe}        Recipe dataobject
 */
Recipes.GetRecipeBySlug = async function(slug) {
    let { data, status } = await HTTPClient.Get(`/v3/recipes/${slug}`);

    if (status != 200) {
        throw data;
    }

    return new Recipe(data);
};

export default Recipes;
