<template>
    <div :class="`comment level-${level} ${comment.isAdmin() ? 'sticky' : ''}`">
        <div class="comment-inner">
            <div class="user">
                <div :class="`avatar ${comment.getAvatar() ? '' : '-initials'}`">
                    <img v-if="comment.getAvatar()" :src="comment.getAvatar()" style="max-height: 100%" />
                    <span v-else>
                        {{ comment.getInitials() }}
                    </span>
                </div>
                <div class="info">
                    <span>{{ comment.getName() }}</span>
                    <div class="date">{{ comment.getFormattedDate() }}</div>
                </div>
            </div>
            <div class="content">
                <p>{{ comment.getText() }}</p>
            </div>
        </div>

        <div :class="`sub-comments level-${level}`" v-if="comment.getComments().length > 0">
            <comment
                v-for="comment in comment.getComments()"
                :comment="comment"
                :level="level + 1"
                :key="comment.getId()"
            />
        </div>

        <div class="comment-reply" v-if="level == 0">
            <a href="#" class="btn -link" @click.prevent="openReply = !openReply">
                {{ $t("Reply to this comment") }}
            </a>

            <reply
                v-if="openReply"
                @submitted="addReplyComment"
                :title="$t('Reply')"
                :placeholder="$t('Reply to the above comment')"
            />
        </div>
    </div>
</template>

<script>
import Reply from "@/components/Comments/Reply";

export default {
    name: "Comment",
    components: { Reply },
    props: {
        comment: Object,
        isFeatured: Boolean,
        level: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            openReply: false
        };
    },
    methods: {
        async addReplyComment(text) {
            let success = await this.comment.addReply(text);

            if (!success) {
                return this.$toast({
                    type: "error",
                    message: this.$t("There was an error posting your reply.")
                });
            }

            this.$toast({ type: "success", message: this.$t("Reply posted") });
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
