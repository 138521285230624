<template>
    <div class="comment-container">
        <div class="block reply">
            <div class="block-title">
                <h2>{{ $t("Reply on this recipe") }}</h2>
            </div>
            <reply @submitted="addReplyComment" />
        </div>

        <div class="comments">
            <div class="block-title">
                <h2>{{ $t("Comments ({num})", { num: total_comments }) }}</h2>
            </div>

            <comment
                v-for="comment in comments"
                :comment="comment"
                :key="comment.getId()"
            />

            <span v-if="page != max_page" class="flex justify-center m-8">
                <button class="btn -icon -gray" @click="loadNextPage">
                    {{ $t("Load more comments") }}
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import CommentsApi from "@/lib/api/Comments";
import Comment from "@/components/Comments";
import Reply from "@/components/Comments/Reply";

export default {
    data() {
        return {
            page: 0,
            max_page: 0,
            total_comments: 0,
            comment_pages: {},
        };
    },

    components: {
        Comment,
        Reply,
    },

    props: {
        slug: {
            type: String,
            required: true,
        },
    },

    methods: {
        async addReplyComment(text) {
            let comment = await CommentsApi.ReplyToRecipe(this.slug, text);

            if (!comment) {
                return false;
            }

            this.comment_pages[0] = [comment, ...this.comment_pages[0]];

            this.$toast({ type: "success", message: this.$t("Reply posted") });
        },

        async getPage(pageNum = 0) {
            const resp = await CommentsApi.GetList(this.slug, 0, pageNum);
            this.total_comments = resp.meta.total;
            this.max_page = resp.meta.last_page;
            this.comment_pages[pageNum] = resp.data;
        },

        async loadNextPage() {
            await this.getPage(this.page);
            this.page++;
        },
    },

    computed: {
        comments() {
            let _comments = [];

            Object.values(this.comment_pages).forEach((page) => {
                _comments = _comments.concat(page);
            });

            return _comments;
        },
    },

    async mounted() {
        await this.loadNextPage();
    },
};
</script>

<style></style>
