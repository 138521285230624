<template>
    <Modal
        :title="$t(title)"
        :subtitle="subtitle"
        @close="open = false"
        class="modal-filters">
        <template
            v-if="activeFilter !== ''"
            v-slot:header-content>
            <div class="filter-search">
                <input
                    v-model="search"
                    type="text"
                    :placeholder="$t('Search')"
                />
            </div>
        </template>
        <template v-slot:modal-header-button-start>
            <button
                v-if="activeFilter === ''"
                @click="sendCloseEvent"
                class="btn -icon-only">
                <icon-back />
            </button>
            <button
                v-if="activeFilter !== ''"
                @click="resetActive()"
                class="btn -icon-only">
                <icon-back />
            </button>
        </template>
        <template v-slot:modal-header-button-end>
            <button
                v-if="preSearchFilters.length !== 0"
                @click="reset"
                class="btn -icon-only">
                {{ $t("Reset") }}
            </button>
        </template>
        <ul class="filter-items">
            <li v-for="filter in getFilterType(availableFilters, 'list')"
                :key="`menu_${filter.getSlug()}`"
                class="filter-item"
                @click="setActiveByFilter(filter)">
                <span v-if="activeFilter === ''">
                    {{ filter.getName() }}
                    <label v-if="getActiveFilterValuesString(filter)">
                        {{ getActiveFilterValuesString(filter) }}
                    </label>
                </span>
                <icon-arrow />
                <!--   List of options  -->
                <div
                    v-if="filter.getSlug() === activeFilter"
                    class="filter-items-sub-active">
                    <ul class="filter-items-sub">
                        <li v-for="filterValue in getFilterValuesWithSearch(filter.getFilterValues())"
                            :key="`${filter.getSlug()}_${filterValue.slug}`"
                            class="filter-item-sub">
                            <div class="filter-item-sub-content">
                                <input
                                    @change="onListFilterInput($event, filter)"
                                    :id="filterValue.slug"
                                    type="checkbox"
                                    :checked="isSelected(filter, filterValue.slug)"
                                    :value="filterValue.slug" />
                                <label :for="filterValue.slug">
                                    {{ filterValue.name }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        <ul
            v-if="!activeFilter"
            class="filter-items"
        >
            <li
                v-for="filter in getFilterType(availableFilters, 'slider')"
                :key="`menu_${filter.getSlug()}`"
                class="filter-item -slider"
            >
                <span>{{ filter.getName() }}</span>
                <div class="filter-item-content-slider">
                    <RangeSlider
                        :name="filter.getSlug()"
                        :min="filter.getFilterValues().min"
                        :max="filter.getFilterValues().max"
                        :min-start-value="getSliderMinValue(filter)"
                        :max-start-value="getSliderMaxValue(filter)"
                        @changed="onSliderChange($event, filter)"
                    />
                </div>
            </li>
        </ul>
        <template v-slot:modal-footer>
            <button @click="sendShowRecipesEvent" class="btn -accent -lg">
                {{ actionText }}
            </button>
        </template>
    </Modal>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import AlgoliaClient from "@/lib/AlgoliaClient";
import Modal from "@/components/Modal";
import RangeSlider from "@/components/RangeSlider";
//Assets
import IconBack from "@/assets/icons/icon-arrow-left.svg?inline";
import IconArrow from "@/assets/icons/icon-arrow-right.svg?inline";

export default {
    components: {
        Modal,
        RangeSlider,
        IconBack,
        IconArrow
    },
    name: "FilterModal",
    props: {
        availableFilters: Array
    },
    data() {
        return {
            activeFilter: "",
            title: "Filter",
            search: ""
        };
    },
    methods: {
        ...mapActions("algolia", ["addFilter"]),
        ...mapMutations("algolia", ["removeListValue", "resetPreSearchFilters"]),

        setActive(filterSlug, filterName) {
            this.activeFilter = filterSlug;
            this.title = filterName;
        },
        reset() {
            this.resetPreSearchFilters();
            this.sendChangeEvent();
        },
        setActiveByFilter(filter) {
            this.setActive(filter.getSlug(), "");
        },
        resetActive() {
            this.setActive("", "Filters");
            this.search = "";
        },
        sendCloseEvent() {
            this.$emit("close");
        },
        sendChangeEvent() {
            this.$emit("change");
        },
        getActiveFilterValuesString(filter) {
            const activeValues = AlgoliaClient.getActiveFilterValues(
                this.preSearchFilters,
                filter.getSlug()
            );

            if (!activeValues.length) {
                return "";
            }

            const result = filter.getFilterValues()
                .filter(filterValue => activeValues.includes(filterValue.slug))
                .map(filterValue => filterValue.name)
                .join(", ");

            return result;
        },
        isSelected(filter, slug) {
            const activeValues = AlgoliaClient.getActiveFilterValues(
                this.preSearchFilters,
                filter.getSlug()
            );

            return activeValues.includes(slug);
        },
        sendShowRecipesEvent() {
            this.sendCloseEvent();
            this.$emit("show-events");
        },
        /**
         * @param event
         * @param {Filter} filter
         */
        onListFilterInput(event, filter) {
            const checked = event.target.checked;
            const value = event.target.value;

            if (checked) {
                this.addFilter({
                    filterSlug: filter.getSlug(),
                    value: value,
                    filterType: filter.getType()
                });
                return;
            }

            this.removeListValue({
                filterSlug: filter.getSlug(),
                value: value
            });
        },
        onSliderChange(event, filter) {
            this.addFilter({
                filterSlug: filter.getSlug(),
                value: event,
                filterType: filter.getType()
            });
        },
        getSliderMinValue(filter) {
            const activeValues = AlgoliaClient.getActiveFilterValues(
                this.preSearchFilters,
                filter.getSlug()
            );

            if (!activeValues || !activeValues.length) {
                return filter.getFilterValues().min;
            }

            return activeValues.min;
        },
        getSliderMaxValue(filter) {
            const activeValues = AlgoliaClient.getActiveFilterValues(
                this.preSearchFilters,
                filter.getSlug()
            );

            if (!activeValues || !activeValues.length) {
                return filter.getFilterValues().max;
            }

            return activeValues.max;
        },
        getFilterValuesWithSearch(filterValues) {
            if (!this.search.length) {
                return filterValues;
            }

            return filterValues.filter(
                filterValue => filterValue
                    .name
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
            );
        },
        getFilterType(filters, type) {
            var list = [];

            filters.map(function(filter) {
                if(filter.getType() === type) {
                    list.push(filter);
                }
            });

            return list;
        }
    },
    computed: {
        ...mapState("algolia", ["preSearchFilters", "presearchHitsCount"]),

        actionText() {
            switch (this.presearchHitsCount) {
                case 0:
                    return this.$t("No recipes found");
                case 1:
                    return `${this.$t("Show Recipe")} (${this.presearchHitsCount})`;
                default:
                    return `${this.$t("Show Recipes")} (${this.presearchHitsCount})`;
            }
        },
        subtitle() {
            if (this.search === "" && this.activeFilter === "") {
                return this.$t("Search for a specific recipe");
            }

            return "";
        }
    },
};
</script>

<style lang="scss">
    @import "index";
</style>
