import HTTPClient from "@/lib/HTTPClient";
import store from "@/store";
import Translations from "@/lib/Translations";
const $t = Translations.Get;

const RecipeRatings = {};

RecipeRatings.RateRecipe = async function(slug, rating, notify = true) {
    let resp = await HTTPClient.Post(`/recipes/${slug}/rate`, {
        data: {
            amount: rating
        }
    });

    if (!resp) {
        return false;
    }

    if (resp.status === 201 && notify) {
        store.dispatch("toasts/sendToast", { type: "success", message: $t("Recipe rated succesfully") });
    }

    return resp;
};

export default RecipeRatings;
