<template>
    <div class="week-calendar">
        <button @click="promptCalendarOpen = true" class="btn -icon-only">
            <icon-calendar />
        </button>

        <modal
            :title="$t('Choose a week')"
            v-if="promptCalendarOpen"
            @close="promptCalendarOpen = false"
            class="modal-calendar"
        >
            <template v-slot:modal-header-button-start>
                <button
                    @click="promptCalendarOpen = false"
                    class="btn -icon -icon-only"
                >
                    <iconClose />
                </button>
            </template>
            <ul class="week-list">
                <li v-for="weekNumber in weekNumbers" :key="weekNumber">
                    <button
                        @click="$emit('change', weekNumber)"
                        :class="`${current === weekNumber ? '-active' : ''}`">
                        {{ weekNumber }}
                    </button>
                </li>
            </ul>
        </modal>
    </div>
</template>

<script>
import WeekMenusApi from "@/lib/api/WeekMenu";
import Modal from "@/components/Modal";

import iconCalendar from "@/assets/icons/icon-calendar.svg?inline";
import iconClose from "@/assets/icons/icon-close.svg?inline";

export default {
    props: {
        current: Number
    },
    components: {
        Modal,
        iconCalendar,
        iconClose
    },
    data() {
        return {
            promptCalendarOpen: false,
            weekNumbers: null
        };
    },
    async mounted() {
        this.weekNumbers = await WeekMenusApi.GetWeekNumbers();
    }
};
</script>

<style lang="scss">
@import ".";
</style>
