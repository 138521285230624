<template>
    <div class="range-slider-container">
        <div class="range-slider-container-inner">
            <div class="range-value">{{ min }}</div>
            <div class="range-slider">
                <div class="tooltips">
                    <div class="tooltip" v-if="slider1Tooltip" :style="{ left: `${(sliderValue1 / max) * 100}%` }">
                        <span>{{ sliderValue1 }}</span>
                    </div>
                    <div class="tooltip" v-if="slider2Tooltip" :style="{ left: `${(sliderValue2 / max) * 100}%` }">
                        <span>{{ sliderValue2 }}</span>
                    </div>
                </div>
                <input
                    @change="onSliderChange()"
                    :id="`${name}_slider_1`"
                    type="range"
                    v-model="sliderValue1"
                    :min="minStartValue"
                    :max="maxStartValue"
                    @mousedown="slider1Tooltip = true"
                    @mouseup="slider1Tooltip = false"
                    @touchstart="slider1Tooltip = true"
                    @touchend="slider1Tooltip = false"
                />
                <input
                    @change="onSliderChange()"
                    :id="`${name}_slider_2`"
                    type="range"
                    v-model="sliderValue2"
                    :min="minStartValue"
                    :max="maxStartValue"
                    @mousedown="slider2Tooltip = true"
                    @mouseup="slider2Tooltip = false"
                    @touchstart="slider2Tooltip = true"
                    @touchend="slider2Tooltip = false"
                />
                <div class="range" :style="{ left: `${(sliderValue1 / max) * 100}%`, right: `${(100 - ((sliderValue2 / max) * 100))}%` }"></div>
            </div>
            <div class="range-value">{{ max }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: "RangeSlider",
    props: {
        name: String,
        min: Number,
        max: Number,
        minStartValue: {
            type: Number,
            default: 0
        },
        maxStartValue: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            sliderValue1: this.min,
            sliderValue2: this.max,
            slider1Tooltip: false,
            slider2Tooltip: false
        };
    },
    methods: {
        onSliderChange() {
            this.$emit("changed", {min: this.minValue, max: this.maxValue});
        }
    },
    computed: {
        maxValue() {
            if (this.sliderValue1 >= this.sliderValue2) {
                return this.sliderValue1;
            }

            return this.sliderValue2;
        },
        minValue() {
            if (this.sliderValue1 <= this.sliderValue2) {
                return this.sliderValue1;
            }

            return this.sliderValue2;
        },
    }
};
</script>

<style lang="scss">
@import "index";
</style>
