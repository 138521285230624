<template>
    <div class="daymenu-view -no-padding-top" v-if="daymenu">
        <Header
            :title="$t('Daymenu')"
            :subtitle="daymenu.getTitle()"
            theme="transparent"
        >
            <template v-slot:button-start>
                <button @click="$router.go(-1)" class="btn -icon-only">
                    <iconLeft />
                </button>
            </template>
        </Header>

        <div class="featured-image -small -bg">
            <img :src="daymenu.getMedia('large')" />
            <div class="oval"></div>
        </div>

        <div class="container">
            <div class="button-container flex justify-center">
                <button class="-gray -icon m-10" @click="schedulePopup = true">
                    <iconCalendar />
                    {{ $t("Plan in") }}
                </button>
            </div>

            <kcal-switcher v-if="daymenu" :daymenu="daymenu">
                <template v-slot:actions="{ activeCalorieVariant }">
                    <div class="actions">
                        <favorite-button
                            :title="true"
                            :favorite-object="daymenu"
                            :is-favorite="
                                favoriteDayMenuIds.includes(daymenu.getId())
                            "
                        />
                        <button
                            class="-gray -icon"
                            @click="schedulePopup = true"
                        >
                            <iconCalendar />

                            {{ $t("Plan in") }}
                        </button>
                        <button
                            class="btn -icon -gray"
                            @click="pdfViewerOpen = true"
                            v-if="activeCalorieVariant.getPdfUrl() && isMember"
                        >
                            <iconDownload />
                            PDF
                        </button>
                        <pdf-viewer
                            @close="pdfViewerOpen = false"
                            v-if="pdfViewerOpen"
                            :title="`${daymenu.getTitle()}`"
                            :subtitle="`
                                ${activeCalorieVariant.getCalories()}
                                ${$t('kcal')} - PDF
                            `"
                            :url="activeCalorieVariant.getPdfUrl()"
                        />
                    </div>
                </template>
            </kcal-switcher>

            <div class="recipes">
                <p>{{ $t("Recipes in this menu") }}</p>
                <card
                    v-for="recipe in daymenu.getRecipes()"
                    :url-path="`/recepten/${recipe.getSlug()}`"
                    :title="recipe.getTitle()"
                    :image-url="recipe.getMedia('small')"
                    :kcal-text="`${recipe.getKcal()} ${$t('Kcal')}`"
                    :key="recipe.getSlug()"
                    :nutrition-text="recipe.getMealTypesString()"
                />
            </div>
        </div>
        <modal
            v-if="schedulePopup"
            @close="schedulePopup = false"
            class="schedule-modal -no-padding"
        >
            <button
                @click="schedulePopup = false"
                class="modal-close -icon-only"
            >
                <iconClose />
            </button>
            <calendar
                :start-date="today"
                :selected="plannedDayMenus"
                @close="schedulePopup = false"
                @date-selected="planDayMenu"
            />
            <planned-day-menu-list
                @unplan="unplanDayMenu"
                :selected="plannedDayMenus"
            />
        </modal>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import { DateTime } from "@/lib/Misc";
import DayMenuApi from "@/lib/api/DayMenus";
import FavoriteHelper from "@/lib/FavoriteHelper";
import PlannedDayMenus from "@/lib/api/PlannedDayMenu";
import PlannedDayMenuList from "@/views/DayMenu/PlannedDayMenuList";
import Header from "@/components/Header";
import Card from "@/components/Card";
import Modal from "@/components/Modal";
import Calendar from "@/components/Calendar";
import KcalSwitcher from "@/components/KcalSwitcher";
import FavoriteButton from "@/components/FavoriteButton";
import PdfViewer from "@/components/PdfViewer";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconCalendar from "@/assets/icons/icon-calendar.svg?inline";
import iconClose from "@/assets/icons/icon-close.svg?inline";
import iconDownload from "@/assets/icons/icon-download.svg?inline";

export default {
    name: "Recipe",

    components: {
        Header,
        Card,
        Modal,
        Calendar,
        PlannedDayMenuList,
        KcalSwitcher,
        iconLeft,
        iconCalendar,
        PdfViewer,
        iconDownload,
        iconClose,
        FavoriteButton,
    },
    data() {
        return {
            daymenu: null,
            calorieVariantIndex: 0,
            schedulePopup: false,
            pdfViewerOpen: false,
            selectedMonth: 0,
            plannedDayMenusList: [],
        };
    },
    methods: {
        ...mapActions("plannedDayMenus", [
            "addPlannedDayMenuToMonth",
            "removePlannedDayMenusFromList",
            "setSelectDate",
        ]),
        ...mapMutations("plannedDayMenus", ["setPlannedDayMenus"]),
        async planDayMenu(day) {
            const plannedDayMenu = await PlannedDayMenus.PlanDayMenu(
                this.daymenu.getId(),
                day.date
            );

            this.addPlannedDayMenuToMonth({ day, plannedDayMenu });
            this.setSelectDate();
            this.$toast({
                type: "success",
                message: this.$t("Day menu is now scheduled in"),
            });
        },
        async unplanDayMenu(plannedDayMenu) {
            await PlannedDayMenus.UnplanDayMenu(plannedDayMenu.getId());

            this.removePlannedDayMenusFromList({ plannedDayMenu });
            this.setSelectDate();
        },
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        ...mapState("favorites", ["favoriteDayMenuIds"]),
        ...mapState("plannedDayMenus", ["plannedDayMenus"]),
        ...mapState("user", ["isMember"]),

        slug() {
            return this.$route.params.slug;
        },
        today() {
            return DateTime.NowWithoutTime();
        },
    },

    async mounted() {
        this.daymenu = await DayMenuApi.GetDayMenuBySlug(this.slug);

        // Default calories based on customer setting
        if (this.getUser.getCustomerSetting("default_calories").value) {
            this.calorieVariantIndex = this.daymenu
                .getDayMenuVariantKeys()
                .indexOf(
                    this.getUser.getCustomerSetting("default_calories").value
                );
        }

        if (!this.plannedDayMenus) {
            this.setPlannedDayMenus(await PlannedDayMenus.GetAll());
            this.setSelectDate();
        }

        if (!FavoriteHelper.hasDayMenu) {
            await FavoriteHelper.initDayMenus();
        }
    },
};
</script>

<style lang="scss">
@import "index";
</style>
