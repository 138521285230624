<template>
    <div class="recipe-details">
        <h1>{{ recipe.getTitle() }}</h1>
        <div class="recipe-rating">
            <div class="recipe-rating-stars">
                <div
                    class="star"
                    v-for="star in [1,2,3,4,5]"
                    :class="`${recipe.getAverageRating() > star ? '-active': ''}`"
                    :key="star">
                    <iconStar />
                </div>
            </div>
            <label class="label" v-if="recipe.getTotalRatings() === 0 || recipe.getTotalRatings() > 1">
                ({{ recipe.getTotalRatings() }} {{ $t("ratings") }})
            </label>
            <label class="label" v-else>
                ({{ recipe.getTotalRatings() }} {{ $t("rating") }})
            </label>
        </div>

        <div class="recipe-information">
            <dl class="recipe-information-item">
                <dt class="recipe-information-label" v-if="recipe.getMainPreperationTime()">
                    <iconClock />
                    {{ preperationLabel(recipe.getMainPreperationTime()) }}
                </dt>
                <dd class="recipe-information-list">
                    <ul>
                        <li v-for="(preparation, index) in recipe.getDetailedPreperationTimes()" :key="index">
                            + {{ preperationLabel(preparation) }}
                        </li>
                    </ul>
                </dd>
            </dl>
            <dl class="recipe-information-item">
                <dd class="recipe-information-label">
                    <iconKcal /> {{ recipe.getComputedKcal() }} {{ $t("Kcal") }}
                </dd>
                <dt class="recipe-information-list">
                    <ul>
                        <li>({{ recipe.getNutrientsRelatesTo() }})</li>
                    </ul>
                </dt>
            </dl>
        </div>

        <div class="nutrients">
            <div
                v-for="nutrient in recipe.getNutrients(true)"
                :key="nutrient.code">
                <dl>
                    <dt>{{ nutrient.code }}</dt>
                    <dd>{{ NumberToCommaString(parseFloat(nutrient.value)) }} {{ getUnitShortcode(nutrient.unit) }}</dd>
                </dl>
            </div>
        </div>

        <div v-if="recipe.hasDayMenus()" class="day-menus">
            <Fab
                :title="$t('Daymenus with this recept')">
                <template v-slot:fab-button-icon>
                    <iconRight />
                </template>
                <template v-slot:fab-list>
                    <ul>
                        <li
                            v-for="dayMenu in recipe.getDayMenus()"
                            :key="dayMenu.slug"
                        >
                            <router-link :to="`/dag-menu/${dayMenu.slug}`">
                                {{ dayMenu.name }}
                            </router-link>
                        </li>
                    </ul>
                </template>
            </Fab>
        </div>

        <cms-body
            class="recipe-description"
            :html="recipe.getIntro()" />
    </div>
</template>

<script>
import Fab from "@/components/Fab";
import CmsBody from "@/components/CmsBody";
import { NumberToCommaString } from "@/lib/Misc";
import iconRight from "@/assets/icons/icon-arrow-right.svg?inline";
import iconClock from "@/assets/icons/icon-clock.svg?inline";
import iconKcal from "@/assets/icons/icon-kcal.svg?inline";
import iconStar from "@/assets/icons/icon-star.svg?inline";

export default {
    name: "RecipeDetails",
    props: {
        recipe: Object
    },
    components: {
        Fab,
        iconKcal,
        iconClock,
        iconRight,
        iconStar,
        CmsBody
    },
    methods: {
        NumberToCommaString,
        getUnitShortcode(unit) {
            switch (unit) {
                case "CL":
                    return "cl";
                case "Gram":
                    return "gr";
                default:
                    return "";
            }
        },
        preperationLabel(preparation) {
            return preparation.preparation_time_amount + " " +
                this.$t(preparation.preparation_time_indication) + " " +
                (preparation.preparation_time_text ? preparation.preparation_time_text : "");
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
