import HTTPClient from "@/lib/HTTPClient";
import Filter from "@/models/algolia/Filter";

const Filters = {};

/**
 * Gets recipe by slug
 *
 * - Does API request through HTTPClient
 * - Return Filters as filter dataobject
 *
 * @return  {Filter[]}        Filter dataobject
 */
Filters.GetList = async function() {
    const {data,status}  = await HTTPClient.Get("/recipes/filters/");

    if (parseInt(status) !== 200) {
        console.error(data);
        return [];
    }

    return data.map(filterData => new Filter(filterData));
};

export default Filters;
