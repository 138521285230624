<template>
    <div class="pdf-viewer">
        <modal
            @close="onClose"
            :title="title"
            :subtitle="subtitle"
            class="-big"
        >
            <template v-slot:modal-header-button-start>
                <button @click="onClose" class="btn -icon-only">
                    <iconLeft />
                </button>
            </template>
            <template v-slot:modal-header-button-end>
                <a
                    :href="url"
                    download="download"
                    target="_blank"
                    class="btn -icon-only"
                >
                    <iconDownload />
                </a>
            </template>
            <div class="loading"></div>

            <div class="pdf-body">
                <pdf
                    @numpages="createPages"
                    v-for="page in totalPages"
                    :key="`pdf_page_${page}`"
                    :page="page"
                    :src="url"
                >
                </pdf>
            </div>
        </modal>
    </div>
</template>

<script>
import Pdf from "pdfvuer";

import Modal from "@/components/Modal";

import iconDownload from "@/assets/icons/icon-download.svg?inline";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    name: "PdfViewer",
    props: {
        title: String,
        url: String,
        subtitle: String,
    },
    components: {
        Modal,
        iconDownload,
        iconLeft,
        Pdf,
    },
    data() {
        return {
            modalOpen: false,
            random: 0,
            pages: 1,
            totalPages: [1],
        };
    },
    computed: {
        getPdfViewerUrl() {
            return `//docs.google.com/gview?url=${this.url}&embedded=true&random=${this.random}`;
        },
    },
    methods: {
        onClose() {
            this.$emit("close");
        },
        createPages(pages) {
            // One page is always present
            if (parseInt(pages) === 1) {
                return;
            }

            // Checking if we already have all the necessary pages
            if (this.totalPages.length === parseInt(pages)) {
                return;
            }

            this.totalPages = [...Array(pages).keys()].map(
                (pageNum) => pageNum + 1
            );
        },
    },
};
</script>

<style lang="scss">
@import "index";
</style>
