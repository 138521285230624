<template>
    <div class="block ingedients">
        <div class="block-title">
            <h2>{{ $t("Ingredients") }} ({{ relatesTo }})</h2>
        </div>

        <cms-body
            class="block-content"
            :html="ingredients" />
    </div>
</template>

<script>
import CmsBody from "@/components/CmsBody";
export default {
    name: "RecipeIngredients",
    props: {
        relatesTo: String,
        ingredients: String
    },
    components: {
        CmsBody
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
