import DataObject from "./DataObject";
import DayMenu from "./DayMenu/DayMenu";
import Recipe from "./Recipe";
import config from "@/config";

export default class WeekMenu extends DataObject {
    constructor(data) {
        super(data);
    }

    getDayMenus() {
        return this.getData("day_menus")
            .map(i => new DayMenu(i));
    }

    getRecipes() {
        return this.getData("recipes")
            .map(i => new Recipe(i));
    }

    getPdfByType(type) {
        return this.getData("pdfs")
            .find(i => i.type === type);
    }

    getWeekMenuPdfByCalorie(calorie) {
        return this.getData("pdfs").find(
            (i) => i.type === "WeekMenu" && i.calorie === calorie
        );
    }

    getGroceryListPdfUrl() {
        return `${config.media_url}/pdf-download/${
            this.getPdfByType("Groceries").id
        }`;
    }

    getPdfUrlByCalorie(calorie) {
        return `${config.media_url}/pdf-download/${
            this.getWeekMenuPdfByCalorie(calorie).id
        }`;
    }
}
