import DataObject from "../DataObject";
import config from "@/config";
import placeholder from "@/assets/images/placeholder.jpg";

export default class Hit extends DataObject {
    constructor(data) {
        super(data);
    }

    getMedia(size = null) {
        const media = this.getData("media/imageConversions");

        if (!media) {
            return placeholder;
        }

        if (!size) {
            return media;
        }

        if (!media[size]) {
            return placeholder;
        }

        return `${config.media_url}${media[size]}`;
    }

    getMealTypesString() {
        return (this.getData("GroupedTags/mealType") || [])
            .map((mealtype) => mealtype.name)
            .join(", ");
    }

    getObjectID() {
        return parseInt(this.getData("objectID"));
    }

    /**
     * Alias for getObjectID, this used for state management.
     */
    getID() {
        return this.getObjectID();
    }

    getId() {
        return this.getObjectID();
    }

    getClassType() {
        return "Hit";
    }
}
