import HTTPClient from "@/lib/HTTPClient";
import DayMenu from "@/models/DayMenu/DayMenu";

const DayMenus = {};

DayMenus.GetDayMenuBySlug = async function(slug) {
    let { data, status } = await HTTPClient.Get(`/v3/daymenus/${slug}`);

    if (status != 200) {
        throw new Error(data);
    }

    data = data.data;

    data.day_menu_variants = data.day_menu_variants.map((item) => {
        const pdfs = data.pdfs;

        if (pdfs) {
            const calories = item.calories;
            for (let pdf of pdfs) {
                const path = pdf.path.split("/");
                const pdfCalories = parseInt(path[path.length - 1]);

                if (pdfCalories !== calories) {
                    continue;
                }

                item.pdf = pdf;
            }
        }

        return item;
    });

    return new DayMenu(data);
};

export default DayMenus;
