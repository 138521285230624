<template>
    <Header
        :title="`${$t('Week {num}', { num: weekNumber })}`"
        :subtitle="$t(active_day)"
    >
        <template v-slot:button-end>
            <week-calendar :current="weekNumber" @change="changeWeek" />
        </template>
    </Header>
    <div class="container">
        <up-next-slider v-if="week_menu" :recipes="week_menu.getRecipes()" />

        <week-menu-switcher
            v-if="week_menu"
            :day-menus="week_menu.getDayMenus()"
            :relative-days="false"
            @active-day-name="changeActiveDayName"
            @change-kcal="selectedKcal = $event"
        />

        <div class="buttons">
            <pdf-viewer
                v-if="pdfGroceryListViewerOpen"
                :title="`${$t('Grocery list')} - PDF`"
                :url="week_menu.getGroceryListPdfUrl()"
                @close="pdfGroceryListViewerOpen = false"
            />

            <button
                class="btn -icon -accent"
                @click="pdfGroceryListViewerOpen = true"
            >
                <iconDownload />
                {{ $t("Grocery list") }}
            </button>

            <pdf-viewer
                v-if="pdfWeekMenuViewerOpen"
                :title="`${$t('Week menu')} - PDF`"
                :subtitle="`
                    ${selectedKcal}
                    ${$t('kcal')} - PDF
                `"
                :url="week_menu.getPdfUrlByCalorie(selectedKcal)"
                @close="pdfWeekMenuViewerOpen = false"
            />

            <button
                class="btn -icon -gray"
                @click="pdfWeekMenuViewerOpen = true"
                v-if="isMember"
            >
                <iconDownload />
                PDF
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import DateTime from "@/lib/misc/DateTime";
import WeekMenuApi from "@/lib/api/WeekMenu";
import Header from "@/components/Header";
import WeekMenuSwitcher from "@/components/WeekMenuSwitcher";
import UpNextSlider from "@/components/UpNextSlider";
import PdfViewer from "@/components/PdfViewer";
import iconDownload from "@/assets/icons/icon-download.svg?inline";
import WeekCalendar from "./WeekCalendar";

export default {
    name: "WeekMenu",
    components: {
        Header,
        WeekMenuSwitcher,
        UpNextSlider,
        PdfViewer,
        WeekCalendar,
        iconDownload,
    },
    data() {
        return {
            week_menu: null,
            active_day: DateTime.GetDayName(new Date().getDay()),
            pdfGroceryListViewerOpen: false,
            pdfWeekMenuViewerOpen: false,
            selectedKcal: null,
            promptCalendarOpen: false,
        };
    },
    methods: {
        changeActiveDayName(data) {
            this.active_day = data;
        },
        changeWeek(weekNumber) {
            this.$router.push(`/week-menu/week-${weekNumber}`);
        },
    },
    computed: {
        ...mapState("user", ["isMember"]),
        weekNumber() {
            const weekquery = this.$route.params.weekquery;
            return weekquery === ""
                ? null
                : parseInt(weekquery.match(/\d+/)[0]);
        },
    },
    async mounted() {
        if (!this.weekNumber) {
            this.changeWeek(DateTime.getWeekNumber(DateTime.Now()));
            return;
        }

        this.week_menu = await WeekMenuApi.GetWeekMenu(this.weekNumber);
    },
};
</script>

<style lang="scss">
@import "_index";
</style>
