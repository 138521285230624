<template>
    <div class="block">
        <div class="block-title">
            <h2>{{ $t("Rate this recipe") }}</h2>
        </div>
        <div class="block-content">
            <div class="stars">
                <div
                    class="star"
                    v-for="star in [1,2,3,4,5]"
                    :class="`${current_user_rating >= star ? '-active': ''}`"
                    :key="star" @click="reviewRecipe(star)">
                    <iconStar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RecipeRatingsApi from "@/lib/api/RecipeRatings";

import iconStar from "@/assets/icons/icon-star.svg?inline";

export default {
    components: {
        iconStar
    },

    name: "RecipeRatings",

    props: {
        recipe: Object
    },

    data() {
        return {
            current_user_rating: this.recipe.getCurrentUserRating()
        };
    },

    computed: {
        slug() {
            return this.$route.params.slug;
        }
    },

    methods: {
        reviewRecipe(rating) {
            RecipeRatingsApi.RateRecipe(this.slug, rating);
            this.recipe.setData("CurrentUserRating", rating);
            this.current_user_rating = rating;
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
