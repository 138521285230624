<template>
    <div class="pagination">
        <button
            class="btn -gray"
            @click="submitClickedEvent"
            v-if="page + 1 < totalPages"
        >
            {{ $t("Load more recipes") }}
        </button>
        <div v-if="page + 1 >= totalPages">
            {{ totalText }}
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";

export default {
    name: "Pagination",
    props: {
        totalItems: Number,
    },
    methods: {
        submitClickedEvent() {
            this.$emit("clicked");
        }
    },
    computed: {
        ...mapState("algolia", ["page", "totalPages"]),

        totalText() {
            return this.totalItems === 1 ?
                this.$t("Total 1 recipe") :
                this.$t("Total {totalItems} recipes", {totalItems: this.totalItems});
        }
    },
};
</script>

<style lang="scss">
@import "index";
</style>
