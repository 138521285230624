<template>
    <table class="calendar-month">
        <thead>
            <tr>
                <th colspan="7" class="table-title">{{ $t(months[month]) }}</th>
            </tr>
            <tr>
                <th v-for="day in days" :key="day">{{ $t(day) }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(week, index) in monthData" :key="`${index}_week`">
                <td
                    v-for="day in week"
                    :key="`${day.date.getMonth()}_${day.date.getDate()}_${day.date.getYear()}`"
                    @click="submitClickedEvent(day)"
                >
                    <span
                        :class="{
                            strikethrough: !day.available,
                            selected: day.selected,
                            next: !day.inMonth,
                            today: day.isToday,
                        }"
                    >
                        {{ day.date.getDate() }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import {DateTime} from "@/lib/Misc";

export default {
    props: {
        month: Number,
        year: Number,
        startDate: Date
    },
    name: "Month",
    data() {
        return {
            days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            monthData: []
        };
    },
    methods: {
        ...mapGetters("plannedDayMenus", ["getSelectedDates"]),
        /**
         * @param {int} month  The month as a number between 0 and 11 (January to December).
         * @param {int} year The year
         * @return {[Date[]]} List with date objects for each day of the month
         */
        getWeeksInMonth(year, month) {
            // Making sure the month and year is a int
            year = parseInt(year);
            month = parseInt(month);

            const date = new Date(year, month, 1, 0, 0, 0, 0);
            let days = [];
            const perChunk = 7;

            while (date.getMonth() === month) {
                // Checking the start of the months is monday.
                if (date.getDate() === 1 && date.getDay() !== 1) {
                    const startOfWeek = this.getStartOfWeek(date);
                    days = days.concat(startOfWeek);
                }

                days.push(this.createDay(date));
                date.setDate(date.getDate() + 1);
            }

            // Checking if It's Monday
            if (date.getDay() !== 1) {
                const endOfWeek = this.getEndOfWeek(date);
                days = days.concat(endOfWeek);
            }

            return days.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / perChunk);

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                }

                resultArray[chunkIndex].push(item);

                return resultArray;
            }, []);
        },
        getStartOfWeek(date) {
            const result = [];
            let mutatingDate = DateTime.Date(date); // Clone of the original date. This prevents changes to the original
            const day = mutatingDate.getDay();
            const diff = mutatingDate.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            mutatingDate = DateTime.Date(mutatingDate.setDate(diff));

            while (date.getTime() > mutatingDate.getTime()) {
                result.push(this.createDay(mutatingDate));
                mutatingDate.setDate(mutatingDate.getDate() + 1);

                // Prevent infinite loop
                if (result.length > 7) {
                    break;
                }
            }

            return result;
        },
        compareDatesForAvailability(date1, date2) {
            return date1.getTime() >= date2.getTime();
        },
        getEndOfWeek(date) {
            const result = [];
            const lastDay = 1; // Monday

            let mutatingDate = DateTime.Date(date); // Clone of the original date. This prevents changes to the original

            while (mutatingDate.getDay() !== lastDay) {
                result.push(this.createDay(mutatingDate));
                mutatingDate.setDate(mutatingDate.getDate() + 1);

                // Prevent infinite loop
                if (result.length > 7) {
                    break;
                }
            }

            return result;
        },
        submitClickedEvent(day) {
            return this.$emit("clicked", day);
        },
        createDay(date) {
            return {
                date: DateTime.Date(date),
                available: this.compareDatesForAvailability(date, this.startDate),
                selected: this.selectedDates.includes(date.getTime()),
                inMonth: date.getMonth() === this.month,
                isToday: date.getTime() === this.startDate.getTime()
            };
        }
    },
    computed: {
        ...mapState("plannedDayMenus", ["selectedDates"]),
        monthName() {
            return DateTime.GetMonthName(this.month);
        }
    },
    mounted() {
        this.monthData = this.getWeeksInMonth(this.year, this.month);
    },
};
</script>

<style lang="scss">
@import "index";
</style>
