<template>
    <fab
        v-if="currentIndex"
        :title="$t(indexList[currentIndex].sortTitle)">
        <template v-slot:fab-button-icon>
            <iconRight />
        </template>
        <template v-slot:fab-list>
            <ul>
                <li v-for="(index, indexName) in indexList"
                    :class="`${indexName === currentIndex ? '-active' : ''}`"
                    @click="setSortIndex(indexName)"
                    :key="indexName">
                    {{ $t(index.sortTitle) }}
                </li>
            </ul>
        </template>
    </fab>
</template>
<script>
import config from "@/config";
import AlgoliaClient from "@/lib/AlgoliaClient";
import Fab from "@/components/Fab";
import iconRight from "@/assets/icons/icon-arrow-right.svg?inline";

export default {
    name: "Sort",
    components: {
        Fab,
        iconRight
    },
    data() {
        return {
            indexList: config.algolia.indexList,
            currentIndex: null
        };
    },
    methods: {
        setSortIndex(index) {
            AlgoliaClient.setSortIndex(index);
            this.$emit("changed");

            this.currentIndex = index;
        }
    },
    mounted() {
        if (!this.currentIndex) {
            if (AlgoliaClient.index && AlgoliaClient.index.indexName) {
                this.currentIndex = AlgoliaClient.index.indexName;
            }
            else {
                this.currentIndex = Object.keys(this.indexList)[0];
            }
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
