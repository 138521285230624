<template>
    <div class="block steps">
        <div class="block-title">
            <h2>{{ $t("That's how you make it") }}</h2>
        </div>
        <div class="block-content">
            <div class="step" v-for="(step, index) in steps" :key="`step/${index}`">
                <dt><label>Stap {{ index + 1 }}</label></dt>
                <dd>
                    <cms-body
                        :html="step" />
                </dd>
            </div>
        </div>
    </div>
</template>

<script>
import CmsBody from "@/components/CmsBody";
export default {
    name: "RecipeSteps",
    props: {
        steps: Array
    },
    components: {
        CmsBody
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
