<template>
    <div class="tips-n-tricks">
        <div class="block-title">
            <h2>{{ $t("Tips & Tricks") }}</h2>
        </div>
        <Slider
            :navigation="true"
            :responsive="false"
            :pagination="false"
            :stepper="true"
            :space-between="10"
        >
            <template
                v-for="(tip, index) in tips"
                v-slot:[`slide-${index}`]
                :key="`tip/${index}`"
            >
                <label class="tip-index">
                    {{ $t("Tip {tipIndex}", { tipIndex: index + 1 }) }}
                </label>
                <cms-body :html="tip" class="tip-content" />
            </template>
        </Slider>
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import CmsBody from "@/components/CmsBody";

export default {
    components: {
        Slider,
        CmsBody,
    },
    name: "RecipeTips",
    props: {
        tips: Array,
    },
};
</script>

<style lang="scss">
@import "index";
</style>
