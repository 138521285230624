<template>
    <div class="day-menu-list">
        <div class="title">{{ $t("You have planned a daymenu here") }}</div>
        <ul v-if="selected">
            <li
                v-for="chunk in selected"
                :key="`selected_${chunk.date.getMonth()}_${chunk.date.getFullYear()}`">
                <div class="month">{{ getMonthName(chunk.date.getMonth()) }}</div>
                <card
                    v-for="plannedDayMenu in chunk.plannedDayMenus"
                    :url-path="`/dag-menu/${plannedDayMenu.getDayMenuSlug()}`"
                    :title="plannedDayMenu.getDayMenuTitle()"
                    :image-url="plannedDayMenu.getMedia('small')"
                    :key="plannedDayMenu.getId()"
                    :calendar-text="plannedDayMenu.getFormattedPlannedAtDate()"
                    theme="list"
                >
                    <template v-slot:card-actions>
                        <button
                            @click="sendUnPlanEvent(plannedDayMenu)"
                            class="btn -icon -icon-only">
                            <iconTrash />
                        </button>
                    </template>
                </card>
            </li>
        </ul>
        <ul v-else>
            <li>
                <div class="month">{{ $t("You haven't planned a daymenu yet") }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
import {DateTime} from "@/lib/Misc";
import Card from "@/components/Card";

import iconTrash from "@/assets/icons/icon-trash.svg?inline";

export default {
    props: {
        selected: Number
    },
    components: {
        Card,
        iconTrash
    },
    name: "PlannedDayMenuList",
    methods: {
        sendUnPlanEvent(plannedDayMenu) {
            this.$emit("unplan", plannedDayMenu);
        },
        getMonthName(monthIndex) {
            return this.$t(DateTime.GetMonthName(monthIndex));
        },
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
