<template>
    <form @submit.prevent="onSubmit">
        <textarea cols="30" rows="10" v-model="content" :placeholder="placeholder"></textarea>
        <button type="submit" class="btn -accent -icon">
            <iconPlane />

            {{ title }}
        </button>
    </form>
</template>

<script>
import iconPlane from "@/assets/icons/icon-plane.svg?inline";
import Translations from "@/lib/Translations";
const $t = Translations.Get;

export default {
    props: {
        title: {
            type: String,
            default: $t("Submit reply")
        },
        placeholder: {
            type: String,
            default: $t("Write your reply on this recipe")
        }
    },
    data() {
        return {
            content: ""
        };
    },
    components: {
        iconPlane
    },
    methods: {
        onSubmit() {
            if (!this.content) {
                return this.$toast({
                    type: "error",
                    message: this.$t("Please fill in a reply")
                });
            }

            this.$emit("submitted", this.content);
            this.content = "";
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
