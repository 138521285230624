import HTTPClient from "@/lib/HTTPClient";
import WeekMenu from "@/models/WeekMenu";

const WeekMenus = {
    _WeekNumbers: null
};

WeekMenus.GetWeekMenu = async function(weekNumber) {
    let {data, status} = await HTTPClient.Get(`/v3/week-menus/${weekNumber}`);

    if (status != 200) {
        throw new Error(data);
    }

    data = data.data;

    data.pdfs = data.pdfs.map(pdf => {
        if (pdf.type !== "WeekMenu") {
            return pdf;
        }

        const path = pdf.path.split("/");
        const calorie = parseInt(path[path.length - 1]);

        return {
            ...pdf,
            calorie
        };
    });

    return new WeekMenu(data);
};

WeekMenus.GetWeekNumbers = async function() {
    if (WeekMenus._WeekNumbers) {
        return WeekMenus._WeekNumbers;
    }

    const {data, status} = await HTTPClient.Get("/week-menus/weeks");

    if (status != 200) {
        throw new Error(data);
    }

    WeekMenus._WeekNumbers = data.data;

    return data.data;
};

export default WeekMenus;
