import DataObject from "../DataObject";

export default class Filter extends DataObject {
    constructor(data) {
        super(data);
    }

    /**
     * @returns {string}
     */
    getName() {
        return this.getFilterName();
    }

    /**
     * @returns {string}
     */
    getSlug() {
        return this.getFilterSlug();
    }

    /**
     * Get the type of the filter
     *
     * @returns {string}
     */
    getType() {
        switch (this.getSlug()) {
            case "Kcal":
                return "slider";
            default:
                return "list";
        }
    }
}
