<template>
    <div class="calendar">
        <slider
            :navigation="true"
            :pagination="false"
            :stepper="false"
            :responsive="false"
            :space-between="10"
            v-if="availableMonths.length > 0"
        >
            <template
                v-for="(monthDate, index) in availableMonths"
                v-slot:[`slide-${index}`]
                :key="`month/${monthDate.getMonth()}/year/${monthDate.getFullYear()}`"
            >
                <month
                    :key="`${monthDate.getMonth()}_${monthDate.getFullYear()}_${selectedDates.length}`"
                    :month="monthDate.getMonth()"
                    :year="monthDate.getFullYear()"
                    :start-date="startDate"
                    @clicked="showConfirm"
                />
            </template>
        </slider>

        <modal-alert
            v-if="confirmModal.open"
            @close="confirmModal.open = false"
            @cancel="confirmModal.open = false"
            @submit="confirmPlannedDaymenuSelect"
            :title="$t('Schedule daymenu')"
            :content="confirmModal.text"
            :submit-title="$t('Yes')"
            :cancel-title="$t('No')"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import Month from "@/components/Calendar/Month";
import Slider from "@/components/Slider";
import ModalAlert from "@/components/Modal/Alert";
import DateTime from "@/lib/misc/DateTime";

export default {
    props: {
        startDate: Date,
    },
    components: {
        Month,
        Slider,
        ModalAlert,
    },
    name: "Calendar",
    data() {
        return {
            currentMonth: null,
            currentYear: null,
            availableMonths: [],
            confirmModal: {
                open: false,
                text: "",
                selectedDay: null,
            },
        };
    },
    methods: {
        emitClose() {
            this.$emit("close");
        },
        async sendDateSelectedEvent(day) {
            await this.$emit("date-selected", day);
        },
        canShowPreviousMonth() {
            return (
                this.currentYear > this.startYear ||
                this.currentMonth > this.startMonth
            );
        },
        createAvailableMonths() {
            const totalMonths = 12;
            let mutatingDate = DateTime.Date(DateTime.Date(this.startDate).setDate(1)); // Clone of the original date. This prevents changes to the original

            for (let i = 1; i <= totalMonths; i++) {
                this.availableMonths.push(DateTime.Date(mutatingDate));
                mutatingDate.setMonth(mutatingDate.getMonth() + 1);
            }
        },
        confirmPlannedDaymenuSelect() {
            this.sendDateSelectedEvent(this.confirmModal.selectedDay);
            this.confirmModal = {
                open: false,
                text: "",
                selectedDay: null,
            };
        },
        showConfirm(day) {
            if (!day.available) {
                return;
            }

            this.confirmModal.selectedDay = day;
            this.confirmModal.open = true;

            if (day.selected) {
                this.confirmModal.text = this.$t(
                    "There is already a daymenu on this date. Do you want to overwrite this?"
                );
                return;
            }

            this.confirmModal.text = this.$t(
                "Are you sure you want to schedule this daymenu?"
            );
        },
    },
    computed: {
        ...mapState("plannedDayMenus", ["plannedDayMenus", "selectedDates"]),
        startMonth() {
            return this.startDate.getMonth();
        },
        startYear() {
            return this.startDate.getFullYear();
        },
        monthName() {
            let result = this.getMonthName(this.currentMonth);

            if (this.currentYear > this.startYear) {
                result += ` ${this.currentYear}`;
            }

            return result;
        },
    },
    mounted() {
        this.currentMonth = this.startMonth;
        this.currentYear = this.startYear;
        this.createAvailableMonths();
    },
};
</script>

<style lang="scss">
@import "index";
</style>
