<template>
    <div class="recipes-overview">
        <Header
            :title="$t('Recipes')"
            :subtitle="$t('All the recipes in a list')"
            theme="transparent"
        />

        <div class="container">
            <form class="search-form" @submit.prevent="search">
                <input
                    class="search-input"
                    :placeholder="$t('Find a recipe')"
                    @change="getPreSearchHits"
                    v-model="queryData"
                />
                <button class="btn -accent -icon" @click="inputSearch">
                    <iconSearch />
                    {{ $t("Search") }}
                </button>
            </form>

            <div class="recipe-toolbar">
                <div class="recipe-toolbar-filter">
                    <button
                        @click="openFilterModal = true"
                        class="btn -gray -icon"
                    >
                        <iconFilter />
                        {{ $t("Filter") }}
                        <span v-if="totalHits > currentHitsCount">
                            {{ currentHitsCount }}
                        </span>
                    </button>
                </div>
                <Sort @changed="sortSearch" />
            </div>

            <div v-if="totalHits > currentHitsCount" class="active-filters">
                <button
                    class="btn -white -icon"
                    @click="removeActiveSearch"
                    v-if="searchedQuery"
                >
                    {{ $t("You searched for ''{query}''", { query: searchedQuery }) }}
                    <iconClose />
                </button>
                <template
                    v-for="activeFilter in getFlatActiveFilters()"
                    :key="`${activeFilter.filterSlug}_${activeFilter.slug}`"
                >
                    <button
                        class="btn -white -icon"
                        v-if="activeFilter.type === 'list'"
                        @click="removeActiveListValue(activeFilter)"
                    >
                        {{ activeFilter.valueName }}
                        <iconClose />
                    </button>

                    <button
                        class="btn -white -icon"
                        v-if="activeFilter.type === 'slider'"
                        @click="removeActiveRangeValue(activeFilter)"
                    >
                        {{
                            $t("Kcal from {min} to {max}", {
                                min: activeFilter.min,
                                max: activeFilter.max,
                            })
                        }}
                        <iconClose />
                    </button>
                </template>
            </div>

            <div v-if="openFilterModal">
                <FilterModal
                    @close="openFilterModal = false"
                    :open="openFilterModal"
                    :available-filters="availableFilters"
                    @change="getPreSearchHits"
                    @show-events="resetSearch"
                ></FilterModal>
            </div>
            <div v-if="hits.length" class="recipe-list">
                <card
                    v-for="hit in hits"
                    :url-path="`/recepten/${hit.getSlug()}`"
                    :title="hit.getTitle()"
                    :image-url="hit.getMedia('small')"
                    :kcal-text="`${hit.getKcal()} ${$t('Kcal')}`"
                    :nutrition-text="hit.getMealTypesString()"
                    :key="hit.getSlug()"
                    class="-large"
                >
                    <template v-slot:card-actions>
                        <favorite-button
                            :is-favorite="
                                favoriteRecipeIds.includes(hit.getObjectID())
                            "
                            :favorite-object="hit"
                        />
                    </template>
                </card>
            </div>

            <pagination
                :total-items="currentHitsCount"
                @clicked="getNextPageHits()"
            />
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import AlgoliaClient from "@/lib/AlgoliaClient";
import FavoriteHelper from "@/lib/FavoriteHelper";
import Header from "@/components/Header";
import FilterModal from "@/views/Recipes/FilterModal";
import Sort from "@/views/Recipes/Sort";
import Pagination from "@/views/Recipes/Pagination";
import Card from "@/components/Card";

import FavoriteButton from "@/components/FavoriteButton";

import iconClose from "@/assets/icons/icon-close.svg?inline";
import iconFilter from "@/assets/icons/icon-filter.svg?inline";
import iconSearch from "@/assets/icons/icon-search.svg?inline";

export default {
    name: "Recipes",
    components: {
        Header,
        FilterModal,
        Sort,
        Pagination,
        Card,
        iconClose,
        iconFilter,
        iconSearch,
        FavoriteButton,
    },
    data() {
        return {
            searchedQuery: "",
            queryData: "",
            availableFilters: [],
            openFilterModal: false,
            favoriteIds: [],
        };
    },
    methods: {
        ...mapMutations("algolia", [
            "setQuery",
            "removeListValue",
            "removeFilter",
            "removeListValue",
            "removeRangeFilter",
            "overrideSelectedWithPreSearch",
            "setPage",
            "setTotalHits",
            "setTotalPages",
            "setHits",
            "setcurrentHitsCount",
            "setPresearchHitsCount",
        ]),
        ...mapMutations("favorites", ["setFavoriteRecipes"]),
        ...mapActions("favorites", [
            "addFavoriteRecipe",
            "removeFavoriteRecipe",
        ]),
        ...mapActions("algolia", [
            "addToHits",
        ]),
        async getNextPageHits() {
            this.setQuery(this.queryData);
            this.setPage(this.page + 1);

            const results = await AlgoliaClient.search(
                this.selectedFilters,
                this.page
            );

            this.setcurrentHitsCount(results.nbHits);
            this.addToHits(results.hits);
        },
        async initSearch() {
            if (this.query.length > 0) {
                this.queryData = this.query;
                this.searchedQuery = this.queryData;
            }

            if(this.hits.length > 0) {
                return;
            }

            this.overrideSelectedWithPreSearch();
            const hitPerPage = (this.page + 1) * 20;
            const results = await AlgoliaClient.search(this.selectedFilters, 0, hitPerPage);
            this.setcurrentHitsCount(results.nbHits);

            // this only needs to be done at the start of the search.
            if (this.totalPages) {
                return;
            }

            this.addToHits(results.hits);
            this.setTotalPages(results.nbPages);

            if (this.totalHits === null) {
                this.setTotalHits(results.nbHits);
            }
        },
        async sortSearch() {
            if (this.query.length > 0) {
                this.queryData = this.query;
                this.searchedQuery = this.queryData;
            }

            // First get the hits with the current filters
            const hitPerPage = (this.page + 1) * 20;
            let results = await AlgoliaClient.search(this.selectedFilters, 0, hitPerPage);

            this.setcurrentHitsCount(results.nbHits);
            this.setHits(results.hits);

            // Indexes can have different number of hits, so we need to set total pages for each index
            results = await AlgoliaClient.search(this.selectedFilters, 0);
            this.setTotalPages(results.nbPages);
            this.setcurrentHitsCount(results.nbHits);
        },
        async getPreSearchHits() {
            this.setQuery(this.queryData);
            const results = await AlgoliaClient.search(this.preSearchFilters);

            this.setPresearchHitsCount(results.nbHits);
        },
        resetSearch() {
            this.setHits([]);
            this.setPage(0);
            this.setTotalPages(null);
            this.setcurrentHitsCount(null);
            this.setPresearchHitsCount(null);
            this.initSearch();
        },
        removeActiveListValue({ filterSlug, value }) {
            this.removeListValue({ filterSlug, value });
            this.resetSearch();
        },
        removeActiveRangeValue({ filterSlug }) {
            this.removeRangeFilter({ filterSlug });
            this.resetSearch();
        },
        removeActiveSearch() {
            this.queryData = "";
            this.searchedQuery = this.queryData;
            this.setQuery("");
            this.resetSearch();
        },
        async inputSearch() {
            this.setQuery(this.queryData);
            this.setPage(0);

            const results = await AlgoliaClient.search(this.selectedFilters, this.page);

            this.searchedQuery = this.queryData;
            this.setcurrentHitsCount(results.nbHits);
            this.setPresearchHitsCount(results.nbHits);
            this.setTotalPages(results.nbPages);
            this.setHits(results.hits);
        },
        /**
         * This function saturates selected filter with data from the available filter.
         * then it flattens the data. So we can use it to display the active filters
         * with the correct filter value name and filter name.
         *
         * @return {*[]}
         */
        getFlatActiveFilters() {
            let result = [];

            for (const filter of this.availableFilters) {
                const activeValues = AlgoliaClient.getActiveFilterValues(
                    this.selectedFilters,
                    filter.getSlug()
                );

                if (filter.getType() === "slider") {
                    if (!activeValues.min || !activeValues.max) {
                        continue;
                    }

                    //Making slider a single entry
                    const activeFilter = {
                        type: filter.getType(),
                        filterSlug: filter.getSlug(),
                        filterName: filter.getName(),
                        min: activeValues.min,
                        max: activeValues.max,
                    };

                    result.push(activeFilter);
                    continue;
                }

                //Flatting filter data
                const activeFilters = filter
                    .getFilterValues()
                    .filter((filterValue) =>
                        activeValues.includes(filterValue.slug)
                    )
                    .map((filterValue) => {
                        return {
                            ...filterValue,
                            type: filter.getType(),
                            filterSlug: filter.getSlug(),
                            filterName: filter.getName(),
                            value: filterValue.slug,
                            valueName: filterValue.name,
                        };
                    });

                result = result.concat(activeFilters);
            }

            return result;
        },
        async getFilters() {
            this.availableFilters = await AlgoliaClient.getFiltersFromApi();
        },
    },
    computed: {
        ...mapState("algolia", [
            "selectedFilters",
            "preSearchFilters",
            "page",
            "query",
            "totalPages",
            "totalHits",
            "hits",
            "currentHitsCount",
            "presearchHitsCount",
            "scrollPosition"
        ]),
        ...mapState("favorites", ["favoriteRecipeIds"]),
    },
    async mounted() {
        // check if scroll position is set and scroll to it with 0.1 second delay
        if (this.scrollPosition) {
            setTimeout(() => {
                window.scrollTo(0, this.scrollPosition);
            }, 100);
        }

        if (!AlgoliaClient.client) {
            AlgoliaClient.Init();
        }

        await this.initSearch();
        this.availableFilters = await AlgoliaClient.getFiltersFromApi();

        if (!FavoriteHelper.hasRecipes) {
            FavoriteHelper.initRecipes();
        }
    },
};
</script>

<style lang="scss">
@import "index";
</style>
