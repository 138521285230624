<template>
    <div class="recipe -no-padding-top" v-if="recipe">
        <Header
            :title="recipe.getTitle()"
            theme="transparent"
            :scrollposition="400"
        >
            <template v-slot:button-start>
                <button @click="$router.go(-1)" class="btn -icon-only">
                    <iconLeft />
                </button>
            </template>
            <template v-slot:button-end>
                <favorite-button
                    :favorite-object="recipe"
                    :is-favorite="favoriteRecipeIds.includes(recipe.getId())"
                />
            </template>
        </Header>

        <div class="featured-image">
            <img :src="recipe.getMedia('large')" />
            <div class="oval"></div>
        </div>

        <div class="container">
            <div class="recipe-overview">
                <recipe-details :recipe="recipe" />

                <recipe-ingredients
                    :relates-to="recipe.getIngredientsRelatesTo()"
                    :ingredients="recipe.getIngredients()"
                />

                <recipe-steps :steps="recipe.getSteps()" />

                <recipe-tips v-if="recipe.hasTips()" :tips="recipe.getTips()" />

                <pdf-viewer
                    v-if="pdfViewerOpen"
                    :title="`${recipe.getTitle()} - PDF`"
                    :url="recipe.getPdfUrl()"
                    @close="pdfViewerOpen = false"
                />

                <button
                class="btn -icon -gray"
                v-if="isMember"
                @click="pdfViewerOpen = true">
                    <iconDownload />
                    PDF
                </button>

                <recipe-ratings :recipe="recipe" />

                <recipe-comments :slug="slug" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import RecipeApi from "@/lib/api/Recipes";
import RecipeDetails from "@/views/Recipe/Details";
import RecipeIngredients from "@/views/Recipe/Ingredients";
import RecipeSteps from "@/views/Recipe/Steps";
import RecipeTips from "@/views/Recipe/Tips";
import RecipeRatings from "@/views/Recipe/Ratings";
import RecipeComments from "@/views/Recipe/Comments";
import Header from "@/components/Header";
import PdfViewer from "@/components/PdfViewer";
import FavoriteButton from "@/components/FavoriteButton";

import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import iconDownload from "@/assets/icons/icon-download.svg?inline";

export default {
    components: {
        Header,
        RecipeDetails,
        RecipeIngredients,
        RecipeSteps,
        RecipeTips,
        RecipeRatings,
        RecipeComments,
        PdfViewer,
        FavoriteButton,
        iconLeft,
        iconDownload,
    },

    name: "Recipe",

    data() {
        return {
            recipe: null,
            pdfViewerOpen: false,
        };
    },

    computed: {
        ...mapState("favorites", ["favoriteRecipeIds"]),
        ...mapState("user", ["isMember"]),
        slug() {
            return this.recipe
                ? this.recipe.getSlug()
                : this.$route.params.slug;
        },
    },

    async mounted() {
        this.recipe = await RecipeApi.GetRecipeBySlug(this.slug).catch(
            (err) => {
                if (typeof err === "object" && err.length) {
                    err = err[0];
                }

                if (err.message) {
                    err = err.message;
                }

                this.$toast({
                    type: "error",
                    duration: 5000,
                    message: err,
                });

                this.$router.push({
                    path: "/recepten",
                });
            }
        );
        // This fixes a render bug on IOS pwa. When a user redricts back to a
        // recipe the screen will be blank. However when you scroll the page
        // renders correctly.
        // So we added little quick scroll to force this rerender
        // Normaly :key would fix this but this is not the cause
        window.scrollTo({ top: 0 });
        window.scrollTo({ top: 1 });
        window.scrollTo({ top: 0 });
    },
};
</script>

<style lang="scss">
@import "index";
</style>
